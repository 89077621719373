import React, { useState, useEffect } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { Button } from "reactstrap"
import { UncontrolledTooltip } from 'reactstrap'
import { isMobile } from 'react-device-detect';
import "./style.scss"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const url = 'https://storage.googleapis.com/tomaco_catalogos/Catalogo%20Mapple%20Tools.pdf';

const PDFView = () => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [width, setWidth] = useState(window.innerWidth)
  const [scale, setScale] = useState(isMobile ? 1.1 : 0.4);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const { innerWidth: width } = window
      setWidth(width)
    })
  }, [window]) // eslint-disable-line

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages)
  }

  const onDocumentError = (e) => {

  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="pdf-view-custom-div">
        {numPages && (
          <>
            <Button
              id="left"
              className="pdf-view-custom-button-minus"
              disabled={pageNumber === 1}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
            >
              <i className="mdi mdi-chevron-left"></i>

              <UncontrolledTooltip placement="top" target={'left'}>
                Página Anterior
              </UncontrolledTooltip>
            </Button>

            <div className="pdf-view-custom-page-number">
              {`${pageNumber} de ${numPages}`}
            </div>
          </>
        )}

        {pageNumber < numPages && (
          <Button
            id="next"
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
          >
            <i className="mdi mdi-chevron-right"></i>

            <UncontrolledTooltip placement="top" target={'next'}>
              Siguiente Página
            </UncontrolledTooltip>
          </Button>
        )}

        <Button id="download" className="pdf-download-catalog" onClick={() => {
          window.location.href = url
        }}>
          <i className="mdi mdi-download"></i>
          <UncontrolledTooltip placement="top" target={'download'}>
            Descargar Catálogo
          </UncontrolledTooltip>
        </Button>

        {numPages && (
          <>
            <Button
              id="minus"
              className="pdf-view-custom-button-minus"
              onClick={() => {
                setScale(scale - 0.1)
              }}
            >
              <i className="mdi mdi-minus"></i>

              <UncontrolledTooltip placement="top" target={'minus'}>
                Zoom out
              </UncontrolledTooltip>
            </Button>
            <Button
              id="plus"
              className="pdf-view-custom-button-minus"
              onClick={() => {
                setScale(scale + 0.1)
              }}
            >
              <i className="mdi mdi-plus"></i>

              <UncontrolledTooltip placement="top" target={'plus'}>
                Zoom in
              </UncontrolledTooltip>
            </Button>
          </>
        )}
      </div>

      <div className="pdf-viewer-background">
        <Document
          file={`${url}`}
          error={
            <div style={{ margin: "30px 0", color: '#000' }}>
              {"Error al cargar catálogo, presione el botón de descargar..."}
            </div>
          }
          loading={
            <div style={{ margin: "30px 0", color: '#000' }}>
              {"Cargando catálogo..."}
            </div>
          }
          className="pdf-view-custom"
          onLoadSuccess={onDocumentLoad}
          onLoadError={onDocumentError}
        >
          <Page
            className="pdf-view-custom-page"
            loading="Cargando página..."
            width={width - 50}
            pageNumber={pageNumber}
            scale={scale}
          />
        </Document>
      </div>
    </div>
  )
}

export default PDFView
