import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 50px 20px 50px;
  text-align: center;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e30613;
  margin-top: 30px;
`;

export const Link = styled.div`
  border-radius: 8px;
  background-color: #e30613;
  color: #fff;
  font-size: 14px;
  padding: 15px 0 15px 0;
  font-weight: 800;
  line-height: 18px;
  cursor: pointer;
  margin-top: 35px;
`;

export const SubTitle = styled.h1`
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 25px;
`;

export const SubSubTitle = styled.h1`
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const CardHeader = styled.h1`
  fontSize: 2.1875vw;
  marginBottom: 0;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

export const Logo = styled.img`
  margin-top: 120px;
  width: 30%;
  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;
