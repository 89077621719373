/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';

import Landing from '../Landing';
import PdfView from '../PdfView';
 
const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;
 
export function App() {
  return (
    <AppWrapper>
      <Helmet titleTemplate="%s Maple Tools" defaultTitle="Maple Tools">
        <meta name="description" content="Maple Tools" />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/catalogo" component={PdfView} />
      </Switch>
    </AppWrapper>
  );
}

App.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(App);
