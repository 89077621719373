import moment from 'moment';
import logo from '../../assets/mappletools-logo.png';
import {
  Container,
  Title,
  Logo,
  SubTitle,
  Link,
} from './styledComponents';

function Landing({ history }) {
  return (
    <div>
      <Container>
        <Logo src={logo} />
        <div style={{ width: '100%', textAlign: '-webkit-center' }}>
          <Title>
            Conviértete en Distribuidor
          </Title>
        </div>
        <div style={{ width: '100%', textAlign: '-webkit-center' }}>
          <SubTitle>
            Incrementa tus ingresos vendiendo los productos que tenemos para tí.
          </SubTitle>
          <SubTitle>
            Información en <a style={{ color: '#e30613', textDecoration: 'none' }} href="mailto:ventas@mapletools.mx">ventas@mapletools.mx</a>
          </SubTitle>
          <div style={{ width: '150px' }}>
            <Link
              onClick={() => history.push({ pathname: '/catalogo' })}
              style={{ textAlign: 'center' }}
            >
              CATÁLOGO {moment().format('YYYY')}
            </Link>
          </div>
        </div>
      </Container>
    </div >
  );
}

export default Landing;
